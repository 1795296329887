import { Box, ButtonBase, List, ListItem, Typography } from "@mui/material";
import { appTopNavBarHeight } from "../../utils";
import { portalColorScheme } from "../../utils/colorScheme";

export enum OperatorPageOptions {
    LogOfScans = 'LogOfScans',
    SerialNumberRenamingTool = 'SerialNumberRenamingTool',
}

export default function OperatorNav(
    {
        operatorPageType,
        setOperatorPageType
    }: {
        operatorPageType: OperatorPageOptions,
        setOperatorPageType: (operatorPageType: OperatorPageOptions) => void,
    }) {

    const pageOptions = () => {
        return [
            { page: OperatorPageOptions.LogOfScans, label: 'Scan Processing Logs' },
            // TODO: will complete this on subsequent PR
            // { page: OperatorPageOptions.SerialNumberRenamingTool, label: 'Rename SN' },
        ]
    }

    return (
        <>
            <Box sx={{
                minWidth: "180px",
                maxWidth: "180px",
                position: "sticky",
                height: `calc(100vh - ${appTopNavBarHeight}px)`
            }}>
                <List>
                    {pageOptions().map(({ page, label }) => (
                        <ListItem
                            key={page}
                            component={ButtonBase}
                            onClick={() => setOperatorPageType(page)}
                        >
                            {operatorPageType === page && (
                                <Box
                                    sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        backgroundColor: portalColorScheme.lightBlue,
                                        marginRight: 1,
                                    }}
                                />
                            )}
                            <Typography
                                variant="body1"
                                style={{ fontWeight: operatorPageType === page ? '900' : 'normal' }}
                            >
                                {label}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    );

}
