import { useAuth0 } from '@auth0/auth0-react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Link, Typography } from '@mui/material';
import { openTermsPageNewTab } from '../utils';

export default function LoginAuth0() {
    const imgSrc = "./App_inscreen_teaser.png";
    const { loginWithRedirect } = useAuth0();
    return <>
        <Typography variant="body1" sx={{ pt: 3, pb: 3 }}>
            Sign up for a free account to access the demo.
        </Typography>
        <Button variant="outlined" onClick={() => loginWithRedirect()}>Log In/Sign Up</Button>
        <Typography variant="body1" sx={{ pt: 3 }}>
            By using the demo, you are accepting our
            <Link href={openTermsPageNewTab()} target="_blank" sx={{ ml: 1 }}>
                terms of use.
                <OpenInNewIcon style={{ fontSize: 14 }} />
            </Link>
        </Typography>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="top"
            maxWidth={900}
        >
            <img
                src={imgSrc}
                alt={"glimpse site teaser"}
                style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
    </>

};
