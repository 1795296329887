import { Box, Button, Fade, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import { PostingApiStatus } from '../../types';
import { ApiEndpoints } from '../../utils/apiUtils';
import LoadingMessage from '../internal/LoadingMessage';


export default function ScansWithoutSerialNumber() {
    const [scans, setScans] = useState<any[]>([]);
    const [serialNumbers, setSerialNumbers] = useState<{ [key: string]: string }>({});
    const [uploadState, setUploadState] = useState(PostingApiStatus.FALSE);
    const { fetchData, putData } = useAuthTokenAndAccessApi();

    useEffect(() => {
        const fetchScans = async () => {
            try {
                const response = await fetchData(ApiEndpoints.FILE_NAMES);
                setScans(response.data);
                const initialSerialNumbers = response.data.reduce((acc: any, scan: any) => {
                    acc[scan.scan_id] = '';
                    return acc;
                }, {});
                setSerialNumbers(initialSerialNumbers);
            } catch (error) {
                console.error("Error fetching scans:", error);
            }
        };

        fetchScans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSerialNumberChange = (scan_id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSerialNumbers((prev) => ({
            ...prev,
            [scan_id]: event.target.value,
        }));
    };

    // Handle form submission
    const handleSubmit = async () => {
        setUploadState(PostingApiStatus.LOADING);

        const serialNumbersToSubmit = Object.entries(serialNumbers)
            .map(([scan_id, serialNumber]) => {
                if (serialNumber.trim() === '') return null;
                return { scan_id, serial_number: serialNumber };
            })
            .filter(item => item !== null);

        if (serialNumbersToSubmit.length === 0) {
            setUploadState(PostingApiStatus.ERROR);
            return;
        }

        try {
            const response = await putData(ApiEndpoints.RENAME_SERIAL_NUMBER, serialNumbersToSubmit);

            if (response.status === 200) {
                setUploadState(PostingApiStatus.SUCCESS);
            } else {
                setUploadState(PostingApiStatus.ERROR);
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            setUploadState(PostingApiStatus.ERROR);
        } finally {
            setTimeout(() => {
                setUploadState(PostingApiStatus.FALSE);
            }, 1000);
        }
    };

    return (
        <Box sx={{ maxWidth: 1300, ml: 3 }}>
            <Fade in={true}>
                <Grid container>
                    <Grid>
                        <Typography variant="h5" sx={{ flexGrow: 1, pt: '14pt', pb: '14pt', fontWeight: 'bold' }}>
                            Rename Serial Number Tool:
                        </Typography>
                    </Grid>
                </Grid>
            </Fade>

            <Fade in={true}>
                <Grid sx={{ display: 'flex' }}>
                    <TableContainer sx={{ mt: 3 }}>
                        <Table size="small" sx={{ minWidth: 900 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Filename</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Serial Number</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scans.map((scan) => (
                                    <TableRow key={scan.scan_id}>
                                        <TableCell>{scan.file}</TableCell>
                                        <TableCell>{scan.post_processing_completed}</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={serialNumbers[scan.scan_id] || ''}  // Correct value binding
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                onChange={handleSerialNumberChange(scan.scan_id)} // Update state on input change
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Grid item sx={{ pt: 6 }}>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                onClick={handleSubmit} // Submit the form
                            >
                                Submit
                            </Button>
                            <LoadingMessage uploadState={uploadState} />
                        </Grid>
                    </TableContainer>
                </Grid>
            </Fade>
        </Box>
    );
}
